import React from "react";
import loadable from "@loadable/component";

const OfferContainerV1 = loadable(() => import("./OfferContainerV1"));

const OfferContainerComponent = props => {
  const { designVersion } = props;
  switch (designVersion) {
    default:
    case "version1":
      return <OfferContainerV1 {...props} />;
  }
};

export default OfferContainerComponent;
